.prize-revealer {
  font-family: "Fredoka", sans-serif;
  animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  -webkit-animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.prize-img {
  filter: drop-shadow(5px 13px 10px rgba(34, 34, 34, 0.3));
  -webkit-filter: drop-shadow(5px 13px 10px rgba(34, 34, 34, 0.3));
}

.prize-img-entrance {
  animation: scale-in-center-rotate 1.5s;
  -webkit-animation: scale-in-center-rotate 1.5s;
}

.prize-img-ongoing {
  animation: jiggle 1s infinite;
  -webkit-animation: jiggle 1s infinite;
}

.prize-revealer-desc {
  background-color: #36313d;
  box-shadow: 0 10px 15px 0px rgba(0, 0, 0, 0.2),
    10px 10px 60px 0px rgba(0, 0, 0, 0.15) inset,
    inset 0rem 0.3rem 0.2rem #655d70, 0rem 0.7rem 0rem #36313d;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
}

.hidden-gift {
  background: rgb(28, 1, 68);
  background: linear-gradient(
    180deg,
    rgb(22, 0, 54) 0%,
    rgba(14, 1, 33, 1) 47%,
    rgba(13, 0, 28, 1) 100%
  );
}

.hidden-gift-content {
  transform: translate(0, -10rem);
  -webkit-transform: translate(0, -10rem);
  overflow: hidden;
}

.hidden-gift-entrance {
  animation: bounce-in-top 1.1s both;
  animation-iteration-count: 1;
  -webkit-animation: bounce-in-top 1.1s both;
  -webkit-animation-iteration-count: 1;
}

.hidden-gift-shake {
  animation: shake 0.5s infinite;
  -webkit-animation: shake 0.5s infinite;
}

/* Spotlight */

.stage-highlight {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: radial-gradient(
    ellipse closest-side at 50% 87%,
    rgba(255, 255, 255, 0.389),
    rgba(84, 120, 173, 0) 100%
  );
}

.stage-shadow {
  position: absolute;
  width: 60%;
  height: 60%;
  transform: translateY(-2.2rem);
  -webkit-transform: translateY(-2.2rem);
  overflow: hidden;
  background-image: radial-gradient(
    ellipse closest-side at 50% 87%,
    rgba(31, 5, 70, 0.766),
    rgba(255, 6, 6, 0) 100%
  );
}

.lamp {
  position: absolute;
  width: 35vw;
  height: 200vh;
  background-image: radial-gradient(
    ellipse,
    rgba(213, 213, 213, 0.5),
    rgba(198, 198, 198, 0.2) 25%,
    rgba(82, 116, 168, 0) 50%
  );
  top: -60vh;
  transform: perspective(20vw) rotateX(30deg);
  -webkit-transform: perspective(20vw) rotateX(30deg);
}

.lamp-2 {
  opacity: 60%;
  transform: perspective(40vw) rotateX(30deg);
  -webkit-transform: perspective(40vw) rotateX(30deg);
}

.lamp-wrapper {
  position: absolute;
  transform: translate(0, -5rem);
  -webkit-transform: translate(0, -5rem);
  overflow: hidden;
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 10%);
}

.explosion-wrapper {
  filter: blur(2rem);
  -webkit-filter: blur(2rem);
  z-index: 1;
}

.explosion {
  background: white;
  clip-path: circle(50vw at center);
  animation: explode 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  -webkit-animation: explode 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* Prize Details */

.prize-title {
  /* color: #4c09b1; */
  font-family: "Luckiest Guy", cursive;
  font-size: 3.5rem;
  animation: scale-in-center 0.5s;
  -webkit-animation: scale-in-center 0.5s;
  text-shadow: 0rem 0.65rem 0.5rem rgba(0, 0, 0, 0.6);
  position: relative;
  color: transparent;
  -webkit-text-stroke: 0.1rem #dd8903;
}

.prize-title:after {
  content: attr(data-content);
  position: absolute;
  top: 0;
  left: 0;
  text-shadow: none;
  background: #fec400;
  background: -webkit-linear-gradient(to top right, #fec400 0%, #fff3a6 100%);
  background: -moz-linear-gradient(to top right, #fec400 0%, #fff3a6 100%);
  background: linear-gradient(to top right, #fec400 0%, #fff3a6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.prize-subtitle {
  animation: scale-in-center 0.2s;
  -webkit-animation: scale-in-center 0.2s;
}

/* Starburst */

.starburst-wrapper {
  transform: translate(0, -8rem);
  -webkit-transform: translate(0, -8rem);
}

.starburst-1 {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  opacity: 50%;
  width: 150vw;
  filter: blur(0.2rem);
  -webkit-filter: blur(0.2rem);
  animation: rotate-ccw 10s linear infinite;
  -webkit-animation: rotate-ccw 10s linear infinite;
}

.starburst-2 {
  opacity: 30%;
  width: 120vw;
  animation: rotate-ccw 8s linear infinite;
  -webkit-animation: rotate-ccw 8s linear infinite;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-1 19:9:19
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@keyframes explode {
  0% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  100% {
    transform: scale(3);
    -webkit-transform: scale(3);
  }
}

@keyframes jiggle {
  0% {
    transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
    -webkit-transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
  }
}

@keyframes rotate-cw {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@keyframes rotate-ccw {
  from {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
}

@keyframes scale-in-center {
  0% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

@keyframes scale-in-center-rotate {
  0% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  100% {
    transform: scale(1) rotate(-10deg);
    -webkit-transform: scale(1) rotate(-10deg);
  }
}

@keyframes slide-in-bottom {
  0% {
    transform: translateY(1000px);
    -webkit-transform: translateY(1000px);
  }
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}
