.board-wrapper {
  background-color: #f3f4fb;
}

.board-wrapper img {
  max-width: 100%;
}

.board {
  background-color: #36313d;
  animation: float 6s ease-in-out infinite;
  -webkit-animation: float 6s ease-in-out infinite;
}

.board-item-spot {
  background-color: #2c2535;
}

.board-item-spot > img {
  width: 100vw;
}

.board-item-gift {
  -webkit-animation: pulsate-fwd 0.5s ease-in-out infinite both;
  animation: pulsate-fwd 0.5s ease-in-out infinite both;
}

.board-item-zonk:hover {
  animation: shake 0.5s;
  -webkit-animation: shake 0.5s;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}

.board-counter {
  font-family: "Fredoka", sans-serif;
  background-color: #621cc4;
  color: white;
  animation: float 8s ease-in-out infinite;
  -webkit-animation: float 8s ease-in-out infinite;
  box-shadow: inset 0rem 0.2rem 0.5rem #130427;
}

.board-counter-desc {
  color: #ffffff;
  text-shadow: 0rem 0.2rem 0.1rem #410d8a;
}

.board-counter-count {
  font-family: "Iceberg", sans-serif;
  background-color: #310b66;
  color: white;
  box-shadow: inset 0rem 0.2rem 0.5rem #130427;
}

.board-counter-count p {
  background: #f3d1ff;
  background: linear-gradient(to bottom, #ad74ea 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.board-desc {
  font-family: "Fredoka", sans-serif;
  font-size: 1.2rem;
}

@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
    -webkit-transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-0.5rem);
    -webkit-transform: translatey(-0.5rem);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
    -webkit-transform: translatey(0px);
  }
}
